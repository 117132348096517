import React, { MouseEventHandler, useEffect, useRef, useState } from 'react';
import { gsap } from 'gsap';
import Image from 'next/image';
import { useIsMobile } from 'hooks/use-size-class';
import styles from './style.module.scss';
import { buildClassName } from 'utils/build-class-name';
import Link from 'components/link';
import { isClientSide } from 'utils/host-config';

const arrayOfImages = ['/header-video1.mp4', '/assets/images/header-image1.jpg', '/header-video2.mp4', '/assets/images/header-image2.jpg'];
const altText = [
  'A woman gazes out from the balcony, admiring the serene lake view.',
  'Happy family holding keys in front of their new home.',
  'A person holding keys while walking toward a luxury house.',
  'Man in a suit smiling and enjoying an event under bright stage lights.',
];

const RightArrow = ({ small }:{ small?: boolean }) => (
  <svg className={styles['expanding-arrow']} xmlns="http://www.w3.org/2000/svg" width={small ? '23' : '82'} height={small? '13' : '65'} viewBox='0 0 82 65' fill='none'>
    <path d='M61 32L6 32' stroke='white' strokeWidth='10.4348' strokeLinecap='square' strokeLinejoin='bevel'/>
    <path d='M47.6279 8L73.7655 32.5L47.6279 57' stroke='white' strokeWidth='10.4348' strokeLinecap='square'/>
  </svg>
);

const JoinUsButton = ({ small }:{ small?: boolean }) => (
  <Link className={buildClassName('slideUp')} href='https://join.exprealty.com/join-exp-canada/' style={{ zIndex: 999, position: 'absolute', bottom: '30px', right: '100px', fontSize: '28px', fontWeight: '700', display: 'flex', justifyContent: 'center', width: '216px' }}>
    {small && <Image src='/assets/images/header-image1.jpeg' width={67} height={67} style={{ borderRadius: '50%' }} alt='join us button' />}
    <div key='joinUsText' style={{ color: 'white', display: 'flex', alignItems: 'center', width: '120px' }}>
      <span style={{ display: 'flex', justifyContent: 'flex-start', fontWeight: 'bold', color: 'white' }}>Join </span>
      <span style={{ display: 'flex', justifyContent: 'flex-end', fontWeight: 'bold', color: 'white', marginLeft: small ? '5px':'25px' }}>Us</span>
      <RightArrow small/>
    </div>
  </Link>);

const DownArrow = ({ isMobile, handleMouseEnter, handleMouseLeave, screenHeight }:{ isMobile?: boolean; handleMouseEnter: () => void; handleMouseLeave: () => void; screenHeight: number }) => (
  <a
    className={buildClassName(styles['down-arrow'], 'slideUp', 'downArrow')}
    onMouseEnter={ isMobile? undefined : handleMouseEnter}
    onMouseLeave={ isMobile? undefined : handleMouseLeave}
    onClick={e => {
      scrollTo({ top: screenHeight });
      e.stopPropagation();
    }}
  >
    <svg xmlns='http://www.w3.org/2000/svg' width={isMobile? '23' : '56'} height={isMobile? '33' : '64'} viewBox='0 0 56 64' fill='none'>
      <path d='M28 44.9258L28 6.00578' stroke='white' strokeWidth='10.4348' strokeLinecap='square' strokeLinejoin='bevel'/>
      <path d='M48 34.2734L28 55.6102L8 34.2734' stroke='white' strokeWidth='10.4348' strokeLinecap='square'/>
    </svg>
  </a>
);

const slideText = ({ isMobile, handleMouseEnter, handleMouseLeave }:{ isMobile?: boolean; handleMouseEnter: () => void; handleMouseLeave: () => void }) => {
  return [
    <div key='slide1text' id='slide-text-1' className={buildClassName(styles['slide-text-1'])}><span className={styles['highlight-text']}>Make </span><span>Bold</span><span className={styles['highlight-text']}>Moves</span></div>, 
    <div key='slide2text' id='slide-text-2' className={buildClassName(styles['slide-text-2'], isMobile && styles['mobile-slide-text-2'])}><span className={styles['highlight-text']}>Possibilities </span><span className={styles['highlight-text']}>Become</span><span>Reality</span></div>, 
    <div key='slide3text' id='slide-text-3' className={buildClassName(styles['slide-text-3'], isMobile && styles['mobile-slide-text-3'])}><span>It&apos;s </span><span >Your</span><span>Turn</span></div>, 
    <Link 
      key='slide4text' 
      href='https://join.exprealty.com/join-exp-canada/'
      target='_blank' 
      id='slide-text-4'
      className={buildClassName(styles['slide-text-4'], isMobile && styles['mobile-slide-text-4'])}
    ><div id='slide-text-4' onMouseEnter={ isMobile? undefined : handleMouseEnter} onMouseLeave={ isMobile? undefined : handleMouseLeave}><span>Join </span><span>Us <span><RightArrow /></span></span></div></Link>,
  ];
};

export default function AnimatedCarousel() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);
  const [prevSlide, setPrevSlide] = useState(0);
  const imageRefs = useRef<(HTMLImageElement | HTMLDivElement | null)[]>([]);
  const clipPathRef = useRef(null);
  const videoRefs = useRef<(HTMLVideoElement | null)[]>([]);
  const [isHovered, setIsHovered] = useState(false);
  const isMobile = useIsMobile();
  const [mousePositionPixel, setMousePositionPixel] = useState(isMobile? { pixelX: 50, pixelY: 350 } : { pixelX: 50, pixelY: 50 });
  const [activeAutoplay, setActiveAutoplay] = useState(false);

  const [clipPath, setClipPath] = useState(`m${mousePositionPixel.pixelX +250},${mousePositionPixel.pixelY - 450}h102.8c56.5-0.1 102.7 0.1 102.7 0.4 0 0.3-49.2 61.1-109.3 135.3-60.1 74.1-145.8 179.8-190.5 234.9-44.7 55.2-81.2 100.5-81.2 100.8 0 0.3 57.6 71.5 127.9 158.2 70.3 86.8 128.5 158.4 129.2 159.1 1.1 1-18.5 1.2-205.1 1.2l-29.3-36.3c-16.1-19.9-51.1-63.1-77.7-96-26.7-32.9-48.7-59.7-49-59.7-0.3 0.1-6.7 7.9-14.3 17.3-7.5 9.5-15.1 18.8-16.8 20.7-1.7 1.9-29.6 36.1-61.8 76-32.3 39.9-59.5 73.7-62.1 78h-205l5.3-6.2c3-3.5 24.7-30.2 48.3-59.3 23.6-29.1 60.2-74.4 81.4-100.6 21.2-26.2 39.5-48.9 40.8-50.5 1.2-1.6 3.3-4.2 4.5-5.8 1.2-1.6 9-11.3 17.2-21.5 8.3-10.2 34.2-42.2 57.5-71.1 23.4-28.9 72-89 108-133.5 36.1-44.6 111-137 166.5-205.5 55.5-68.5 102.9-127.1 105.4-130.2zm-434.1 153.4l52.3 64.7c36.1 44.6 52.1 65.2 51.5 66-0.5 0.7-10.7 13.4-22.8 28.3-12 14.9-34.6 42.8-78.7 97l-2.7-3c-1.4-1.7-19.2-23.5-39.5-48.5-20.3-25-65.8-81.2-101.2-124.8-35.3-43.6-64.3-79.4-64.2-79.7 0-0.3 46.2-0.4 205.3 0z`);
  const [screenSide, setScreenSide] = useState<'left' | 'right' | null>(null);

  let screenWidth = 0;
  let screenHeight = 0;
  if (isClientSide()) {
    screenWidth = window.innerWidth;
    screenHeight = window.innerHeight;
  }
  const horizontalMidpoint = screenWidth / 2;

  const generateMultiMediaArray = (filePaths: string[]) => {

    return filePaths.map((filePath, index) => {
      const fileExtension = filePath.split('.').pop()?.toLowerCase();

      if (fileExtension && ['jpg', 'jpeg', 'png', 'gif', 'webp'].includes(fileExtension)) {
        return (
          <Image
            key={index}
            src={filePath}
            alt={altText[index]}
            className={buildClassName(`slide${index + 1} carousel-item`, styles.image, (index === 1 && isMobile) && styles['centered-image'], (currentSlide === 1 || currentSlide === 3 ) && styles['subtle-zoom'])}
            layout="fill"
            quality={100}
            style={{
              overflowX: 'visible',
            }}
          />
        );
      } else {
        return (
          <video
            ref={el => {(videoRefs.current[index] = el);}}
            className={styles.video}
            aria-label={altText[index]}
            key={index}
            autoPlay
            playsInline
            webkit-playsinline
            muted
            loop
            controls={false}
            width="100%"
            onContextMenu={e => e.preventDefault()} // Prevents fullscreen
          >
            <source src={filePath} type="video/mp4" />
              Your browser does not support the video tag.
          </video>
        );
      }
    });
  };

  const multiMediaArray = generateMultiMediaArray(arrayOfImages);

  useEffect(() => {
    gsap.set(`#slide-text-${currentSlide+1}`, { opacity: 1 });
    gsap.to(imageRefs.current, { zIndex: 0, opacity: 0, duration: 0.5 });
    gsap.to(imageRefs.current[currentSlide], { zIndex: 1, opacity: 1, duration: 0.5 });
    videoRefs.current[currentSlide]?.play();
    setPrevSlide(currentSlide);
  }, [currentSlide]);

  useEffect(() => {
    if (isMobile) {
      setMousePositionPixel({ pixelX: 50, pixelY: 350 });
    }
    else {
      setMousePositionPixel({ pixelX: 50, pixelY: 50 });
    }
  }, [isMobile]);
  
  useEffect(() => {
    videoRefs.current.forEach(videoRef => {
      if (videoRef) {
        videoRef.setAttribute('playsinline', 'true');
      }
    });

    gsap.set(imageRefs.current, { zIndex: 0, opacity: 0 });
    gsap.set(imageRefs.current[currentSlide], { zIndex: 1, opacity: 1 });

    gsap.set(imageRefs.current[currentSlide], {
      zIndex: 1, opacity: 1,
    });

    setActiveAutoplay(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const AUTOPLAY_DELAY = 10000;
  let autoplayTimeout: NodeJS.Timeout | null = null;
  const startAutoplay = () => {
    autoplayTimeout = setTimeout(() => {
      expandClipPath();
    }, AUTOPLAY_DELAY);
  };
  useEffect(()=> {
    // FOR AUTOPLAY
    autoplayTimeout && clearTimeout(autoplayTimeout);
    if (activeAutoplay && !isHovered) {
      startAutoplay();
    }
    return () => {
      autoplayTimeout && clearTimeout(autoplayTimeout);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeAutoplay, currentSlide, isHovered]);

  const handleMouseMove: MouseEventHandler<HTMLDivElement> = event => {
    if (!isAnimating){
      const rect = event.currentTarget.getBoundingClientRect();
      const pixelX = event.clientX - rect.left;
      const pixelY = event.clientY - rect.top;

      if (event.clientX < horizontalMidpoint) {
        setScreenSide('left');
      } else {
        setScreenSide('right');
      }

      if (isHovered) {
        setMousePositionPixel({ pixelX, pixelY });
        setClipPath(`m${mousePositionPixel.pixelX +250},${mousePositionPixel.pixelY - 450}h102.8c56.5-0.1 102.7 0.1 102.7 0.4 0 0.3-49.2 61.1-109.3 135.3-60.1 74.1-145.8 179.8-190.5 234.9-44.7 55.2-81.2 100.5-81.2 100.8 0 0.3 57.6 71.5 127.9 158.2 70.3 86.8 128.5 158.4 129.2 159.1 1.1 1-18.5 1.2-205.1 1.2l-29.3-36.3c-16.1-19.9-51.1-63.1-77.7-96-26.7-32.9-48.7-59.7-49-59.7-0.3 0.1-6.7 7.9-14.3 17.3-7.5 9.5-15.1 18.8-16.8 20.7-1.7 1.9-29.6 36.1-61.8 76-32.3 39.9-59.5 73.7-62.1 78h-205l5.3-6.2c3-3.5 24.7-30.2 48.3-59.3 23.6-29.1 60.2-74.4 81.4-100.6 21.2-26.2 39.5-48.9 40.8-50.5 1.2-1.6 3.3-4.2 4.5-5.8 1.2-1.6 9-11.3 17.2-21.5 8.3-10.2 34.2-42.2 57.5-71.1 23.4-28.9 72-89 108-133.5 36.1-44.6 111-137 166.5-205.5 55.5-68.5 102.9-127.1 105.4-130.2zm-434.1 153.4l52.3 64.7c36.1 44.6 52.1 65.2 51.5 66-0.5 0.7-10.7 13.4-22.8 28.3-12 14.9-34.6 42.8-78.7 97l-2.7-3c-1.4-1.7-19.2-23.5-39.5-48.5-20.3-25-65.8-81.2-101.2-124.8-35.3-43.6-64.3-79.4-64.2-79.7 0-0.3 46.2-0.4 205.3 0z`);

      }
    }
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleMobileClipPathClick = (side: 'left' | 'right') => {
    setIsHovered(true);

    setClipPath(`m${side === 'left'? 350: 400},${side === 'left'? -100: -100}h102.8c56.5-0.1 102.7 0.1 102.7 0.4 0 0.3-49.2 61.1-109.3 135.3-60.1 74.1-145.8 179.8-190.5 234.9-44.7 55.2-81.2 100.5-81.2 100.8 0 0.3 57.6 71.5 127.9 158.2 70.3 86.8 128.5 158.4 129.2 159.1 1.1 1-18.5 1.2-205.1 1.2l-29.3-36.3c-16.1-19.9-51.1-63.1-77.7-96-26.7-32.9-48.7-59.7-49-59.7-0.3 0.1-6.7 7.9-14.3 17.3-7.5 9.5-15.1 18.8-16.8 20.7-1.7 1.9-29.6 36.1-61.8 76-32.3 39.9-59.5 73.7-62.1 78h-205l5.3-6.2c3-3.5 24.7-30.2 48.3-59.3 23.6-29.1 60.2-74.4 81.4-100.6 21.2-26.2 39.5-48.9 40.8-50.5 1.2-1.6 3.3-4.2 4.5-5.8 1.2-1.6 9-11.3 17.2-21.5 8.3-10.2 34.2-42.2 57.5-71.1 23.4-28.9 72-89 108-133.5 36.1-44.6 111-137 166.5-205.5 55.5-68.5 102.9-127.1 105.4-130.2zm-434.1 153.4l52.3 64.7c36.1 44.6 52.1 65.2 51.5 66-0.5 0.7-10.7 13.4-22.8 28.3-12 14.9-34.6 42.8-78.7 97l-2.7-3c-1.4-1.7-19.2-23.5-39.5-48.5-20.3-25-65.8-81.2-101.2-124.8-35.3-43.6-64.3-79.4-64.2-79.7 0-0.3 46.2-0.4 205.3 0z`);

    gsap.set(clipPathRef.current, { clipPath: 'url(#clipPathX)' });

    expandClipPathMobile(side);
  };

  const handleClipPathClick = () => {
    expandClipPath();
    autoplayTimeout && clearTimeout(autoplayTimeout);
  };

  const expandClipPath = () => {
    setIsAnimating(true);
    console.log(prevSlide+1, 'prevSlide+1');
    console.log(currentSlide, 'currentSlide');

    const tl = gsap.timeline();

    if (clipPathRef.current) {
      tl.fromTo('#clipPathX', {
        scale: 0.6,
      }, {
        duration: 2,
        ease: 'power1.in',
        scale: 100,
        translate: mousePositionPixel.pixelX > window.innerWidth/2 ? '-400px -200px' : '',
      });
      tl.to(`#slide-text-${prevSlide+1}`, {
        opacity: 0,
        duration: 1.25,
        delay: -1.5,
        y: 30,
      });
      tl.to('.downArrow', {
        opacity: 0,
        duration: .5,
        delay: -1.5,
        y: 30,
      });
    }
    tl.to(imageRefs.current[currentSlide], {
      duration: 0.5,
      delay: -0.5,
      opacity: 0,
      onComplete: () => {
        if (screenSide === 'left') {
          if (currentSlide === 0) {
            gsap.set(imageRefs.current[arrayOfImages.length - 1], { zIndex: 1, opacity: 1 });
            setCurrentSlide(arrayOfImages.length - 1);
          } else {
            gsap.set(imageRefs.current[currentSlide - 1], { zIndex: 1, opacity: 1 });
            setCurrentSlide(currentSlide - 1);
          }
        } else {
          if (currentSlide === arrayOfImages.length - 1) {
            gsap.set(imageRefs.current[0], { zIndex: 1, opacity: 1 });
            setCurrentSlide(0);
          } else {
            gsap.set(imageRefs.current[currentSlide + 1], { zIndex: 1, opacity: 1 });
            setCurrentSlide(currentSlide + 1);
          }
        }
        setScreenSide(null);

        gsap.fromTo('.slideUp', { y: -30 }, { duration: 0.5, y: 0 });
        gsap.fromTo('.downArrow', { opacity: 0 }, { duration: 0.5, opacity: 1 });

        gsap.fromTo('#clipPathX',
          {
            scale: 0,
          },
          {
            scale: 0.6,
            duration: 0.5,
            translate:'0px 0px',
          }
        );
        setIsAnimating(false);
      },
    });

  };


  const expandClipPathMobile = (side: 'left' | 'right') => {
    setIsAnimating(true);

    const tl = gsap.timeline();

    if (clipPathRef.current) {
      tl.fromTo('#clipPathX', {
        scale: 0.1,
      }, {
        delay: 0.15,
        duration: 1.5,
        ease: 'power1.inOut',
        scale: 25,
        translate: mousePositionPixel.pixelX > window.innerWidth/2 ? '-400px -200px' : '',
      });
    }
    tl.to(imageRefs.current[currentSlide], {
      duration: 0.5,
      opacity: 0,
      onComplete: () => {
        if (side === 'left') {
          if (currentSlide === 0) {
            gsap.set(imageRefs.current[arrayOfImages.length - 1], { zIndex: 1, opacity: 1 });
            setCurrentSlide(arrayOfImages.length - 1);
          } else {
            gsap.set(imageRefs.current[currentSlide - 1], { zIndex: 1, opacity: 1 });
            setCurrentSlide(currentSlide - 1);
          }
        } else {
          if (currentSlide === arrayOfImages.length - 1) {
            gsap.set(imageRefs.current[0], { zIndex: 1, opacity: 1 });
            setCurrentSlide(0);
          } else {
            gsap.set(imageRefs.current[currentSlide + 1], { zIndex: 1, opacity: 1 });
            setCurrentSlide(currentSlide + 1);
          }
        }
        setScreenSide(null);
        setIsHovered(false);

        gsap.fromTo('.slideUp', { y: 30 }, { duration: 0.5, y: 0 });
        setIsAnimating(false);
      },
    });
  };

  return (
    <>
      <div
        className={buildClassName(styles.carousel, isMobile && styles['mobile-carousel'])}
        onMouseMove={ isMobile? undefined : handleMouseMove}
        onMouseEnter={ isMobile? undefined : handleMouseEnter}
        onMouseLeave={ isMobile? undefined : handleMouseLeave}
        onClick={(isMobile) ? undefined : handleClipPathClick}
      >
        <svg width="0" height="0" preserveAspectRatio='meet'>
          <defs>
            <clipPath
              id="clipPathX"
              style={{
                transform: isMobile? 'scale(0.2)' : 'scale(0.6)',
                transformOrigin: `${mousePositionPixel.pixelX}px ${mousePositionPixel.pixelY}px`,
              }}
              transform={isMobile? 'scale(0.0020, 0.0022)' : 'scale(0.0040, 0.0042)'}
            >
              <path d={clipPath} />
            </clipPath>
          </defs>
        </svg>

        {multiMediaArray.map((element, index) =>
          <div ref={el => {(imageRefs.current[index] = el);}} key={index} >
            <h1 className={buildClassName('slideUp', styles['slide-text'])} style={{ paddingLeft: isMobile ? 0 :30, fontSize: isMobile ? '70px' :'11vw', left: isMobile? '5%':'0', bottom: '30px', width: isMobile ? '90%':'75%' }}>{slideText({ isMobile, handleMouseEnter: handleMouseLeave, handleMouseLeave: handleMouseEnter })[index]}</h1>
            {((index === 1 || index === 2) && !isMobile) && <JoinUsButton small/>}
            {element}
            <DownArrow isMobile={isMobile} handleMouseEnter={handleMouseLeave} handleMouseLeave={handleMouseEnter} screenHeight={screenHeight}/>
            {
              isMobile ?
                <>
                  <div
                    className={styles['left-arrow']}
                    onClick={() => {
                      handleMobileClipPathClick('left');
                      setScreenSide('left');
                    }}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path d="M7.11133 13.2236L2.00133 8.11363L7.11133 3.00363" stroke="white" strokeWidth="3" strokeLinecap="square" strokeLinejoin="bevel"/>
                    </svg>
                  </div>
                  <div
                    className={styles['right-arrow']}
                    onClick={() => {
                      handleMobileClipPathClick('right');
                      setScreenSide('right');
                    }}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path d="M3 3L8.11 8.11L3 13.22" stroke="white" strokeWidth="3" strokeLinecap="square" strokeLinejoin="bevel"/>
                    </svg>
                  </div>
                </>
                : null
            }

          </div>
        )}
        {(isHovered || isMobile) && (
          <div
            ref={clipPathRef}
            className={styles['clip-path']}
            style={{
              clipPath: isMobile? 'none': 'url(#clipPathX)',
            }}
          >
            {(screenSide && screenSide === 'left') ?
              <>
                {multiMediaArray[currentSlide === 0 ? multiMediaArray.length - 1 : currentSlide -1]}
                {!isMobile && <div className={styles['right-arrow-desktop']} style={{ top: `${mousePositionPixel.pixelY - 5}px`, left: `${mousePositionPixel.pixelX - 10}px` }}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="36" viewBox="0 0 19 36" fill="none">
                    <path d="M1.60938 1.47998L17.9724 17.843L1.60938 34.206" stroke="white" strokeWidth="2" strokeLinecap="square" strokeLinejoin="bevel"/>
                  </svg>
                </div>}
              </>
              : (screenSide && screenSide === 'right') ?
                <>
                  {multiMediaArray[currentSlide === arrayOfImages.length - 1 ? 0 : (currentSlide + 1)]}
                  {!isMobile && <div className={styles['left-arrow-desktop']} style={{ top: `${mousePositionPixel.pixelY - 10}px`, left: `${mousePositionPixel.pixelX - 10}px` }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="36" viewBox="0 0 19 36" fill="none">
                      <path d="M1.60938 1.47998L17.9724 17.843L1.60938 34.206" stroke="white" strokeWidth="2" strokeLinecap="square" strokeLinejoin="bevel"/>
                    </svg>
                  </div>}
                </>
                : null
            }
          </div>
        )}
      </div>
    </>

  );
}
