import React from 'react';
import { buildClassName } from 'utils/build-class-name';
import ArrowIcon from 'components/icon/arrow-icon';
import styles from './style.module.scss';

interface Props {
  className?: string;
}

export default function CarouselArrows({ className }: Props) {
  const arrowClass = (type: 'next' | 'prev') => buildClassName(styles.component, styles[type], className);

  return <>
    <span id='prevArrow' className={arrowClass('prev')} ><ArrowIcon /></span>
    <span id='nextArrow'className={arrowClass('next')}><ArrowIcon /></span>
  </>;
}
